const state ={
   para:null
}
const mutations={
    setPara(state,data){
        state.para=data
    }
}
const getters={
    getPara(state){
        return state.para
    }
}
const actions={}
export default {
    namespaced:true,
    getters,
    state,
    mutations,
    actions
}