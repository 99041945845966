import router from "@/router/index";
import cache from '@/utils/cache'
//导入request.js
import { request, baseURL } from "@/utils/request";//@==>src
class Api {
    constructor() {
        this.http = baseURL
        this.apiPost = (url, para) => {
            return new Promise((resolve, reject) => {
                try {
                    request.post(url, { ...para }).then(res => {
                        resolve(res)
                    })
                } catch (err) {
                    console.log(`${url}请求出错`, err);
                }
            })
        }
    }
    // 网站配置
    async getConfig() {
        const res = await request.get(`data/api.data/getConfig`);
        return res.data;
    }
    // 轮播图
    async getSlider() {
        const res = await request.get(`data/api.data/getSlider`);
        return res.data;
    }
    // 竞赛单元
    async getCompetition() {
        const res = await request.get(`data/api.data/getCompetition`);
        return res.data;
    }
    // 专家顾问
    async getConsultant() {
        const res = await request.get(`data/api.data/getConsultant`)
        return res.data
    }
    // 联系我们
    async getContact() {
        const res = await request.get(`data/api.data/getContact`)
        return res.data
    }
    // 获取文章内容
    async getItem(para) {
        const res = await request.get(`data/api.data/getItem`, {
            params: { ...para }
        })
        return res.data
    }
    // 关于大赛数据
    async getPageHome(para) {
        const res = await request.get(`data/api.data/getPageHome`, {
            params: { ...para }
        })
        return res.data
    }
    // 电视节目
    async getTvType(para) {
        const res = await request.get(`data/api.data/getTvType`, {
            params: { ...para }
        })
        return res.data
    }
    // 节目详情
    async getTv(para) {
        const res = await request.get(`data/api.data/getTv`, {
            params: { ...para }
        })
        return res.data
    }
    // 查询文章详情
    async findItem(para) {
        const res = await request.get(`data/api.data/findItem`, {
            params: { ...para }
        })
        return res.data
    }
    // 上传文件
    async upFile(file) {
        try {
            let configs = {
                headers: { 'Content-Type': 'multipart/form-data' },
                timeout: 10 * 60 * 1000
            }, data = new FormData()
            data.set('file', file)
            const res = await request.post(`admin/api.upload/uploadFile`, data, configs)
            console.log('上传文件', res);
            return res.data

        } catch (error) {
            console.log('上传报错', error);
            return
        }
    }
    // 注册
    async register(para) {
        let res = await this.apiPost(`data/api.data/register`, para)
        return res.data
    }
    // 登录
    async login(para) {
        let res = await this.apiPost(`data/api.data/login`, para)
        return res.data
    }
    // 认证信息提交
    async certification(para) {
        let res = await this.apiPost(`data/api.data/certification`, para)
        return res.data
    }
    // 获取机构信息
    async getUserInfo(para) {
        try {
            if (!para.id) {
                alert('登录状态异常')
                cache.clearCache()
                return router.push("/login");
            }
            let res = await this.apiPost(`data/api.data/userInfo`, para)
            if (res.data.code != 1) {
                alert('登录状态异常')
                cache.clearCache()
                return router.push("/login");
            }
            return res.data
        } catch (error) {
            alert('登录状态异常')
            cache.clearCache()
            return router.push("/login");
        }
    }
    // 报名
    async Upapply(para) {
        let configs = {
            timeout: 10 * 60 * 1000
        },
        res = await this.apiPost(`data/api.data/apply`, para,configs)
        return res.data
    }
    // 报名中心过滤查询
    async certificateData(para) {
        let res = await this.apiPost(`data/api.data/certificateData`, para)
        return res.data
    }
    // 成绩查询
    async resultsData(para) {
        let res = await this.apiPost(`data/api.data/resultsData`, para)
        return res.data
    }
    // 佳作展示
    async goodShowData(para) {
        let res = await this.apiPost(`data/api.data/goodShowData`, para)
        return res.data
    }
    // 报名信息详情接口
    async certificateInfo(para) {
        let res = await this.apiPost(`data/api.data/certificateInfo`, para)
        return res.data
    }
    // 防伪码查询
    async findSecurity(para) {
        let res = await this.apiPost(`data/api.data/findSecurity`, para)
        return res.data
    }
	// 成绩证书查询
	async certificateQuery(para) {
	    let res = await this.apiPost(`data/api.data/certificateQuery`, para)
	    return res.data
	}
	// 证书查询
	async certificateApply(para) {
	    let res = await this.apiPost(`data/api.data/certificate_apply`, para)
	    return res.data
	}
	// 教师/学校证书查询
	async certificateQueryTwo(para) {
	    let res = await this.apiPost(`data/api.data/certificateQueryTwo`, para)
	    return res.data
	}
}
let api = new Api()
export default api
