import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 请求列表
import api from '@/api/index'
// 缓存封装
import cache from '@/utils/cache'
// 自定义方法
import variable from "@/utils/variable";
Vue.use(ElementUI);

import axios from 'axios'
Vue.prototype.$axios = axios
Vue.prototype.$api=api
Vue.prototype.$cache=cache
Vue.prototype.$var=variable

import "@/utils/debounce.js";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
