const state ={
    chinaData:[
        {
          deviceType: 0,
          name: "北京",
          center: [116.4551, 40.2539],
          value: 0,
        },
        {
          deviceType: 0,
          name: "天津",
          center: [118.4219, 39.4189],
          value: 0,
        },
        {
          deviceType: 0,
          name: "上海",
          center: [121.4648, 31.2891],
          value: 0,
        },
        {
          deviceType: 0,
          name: "重庆",
          center: [107.7539, 30.1904],
          value: 0,
        },
        {
          deviceType: 0,
          name: "河北",
          center: [115.4004, 37.9688],
          value: 0,
        },
        {
          deviceType: 0,
          name: "河南",
          center: [113.4668, 33.8818],
          value: 0,
        },
        {
          deviceType: 0,
          name: "云南",
          center: [101.8652, 25.1807],
          value: 0,
        },
        {
          deviceType: 0,
          name: "辽宁",
          center: [122.3438, 41.0889],
          value: 0,
        },
        {
          deviceType: 0,
          name: "黑龙江",
          center: [128.1445, 48.5156],
          value: 0,
        },
        {
          deviceType: 0,
          name: "湖南",
          center: [111.5332, 27.3779],
          value: 0,
        },
        {
          deviceType: 0,
          name: "安徽",
          center: [117.2461, 32.0361],
          value: 0,
        },
        {
          deviceType: 0,
          name: "山东",
          center: [118.7402, 36.4307],
          value: 0,
        },
        {
          deviceType: 0,
          name: "新疆",
          center: [84.9023, 41.748],
          value: 0,
        },
        {
          deviceType: 0,
          name: "江苏",
          center: [120.0586, 32.915],
          value: 0,
        },
        {
          deviceType: 0,
          name: "浙江",
          center: [120.498, 29.0918],
          value: 0,
        },
        {
          deviceType: 0,
          name: "江西",
          center: [116.0156, 27.29],
          value: 0,
        },
        {
          deviceType: 0,
          name: "湖北",
          center: [112.2363, 31.1572],
          value: 0,
        },
        {
          deviceType: 0,
          name: "广西",
          center: [108.2813, 23.6426],
          value: 0,
        },
        {
          deviceType: 0,
          name: "甘肃",
          center: [95.7129, 40.166],
          value: 0,
        },
        {
          deviceType: 0,
          name: "山西",
          center: [112.4121, 37.6611],
          value: 0,
        },
        {
          deviceType: 0,
          name: "内蒙古",
          center: [117.5977, 44.3408],
          value: 0,
        },
        {
          deviceType: 0,
          name: "陕西",
          center: [109.5996, 35.6396],
          value: 0,
        },
        {
          deviceType: 0,
          name: "吉林",
          center: [126.4746, 43.5938],
          value: 0,
        },
        {
          deviceType: 0,
          name: "福建",
          center: [118.3008, 25.9277],
          value: 0,
        },
        {
          deviceType: 0,
          name: "贵州",
          center: [106.6113, 26.9385],
          value: 0,
        },
        {
          deviceType: 0,
          name: "广东",
          center: [113.4668, 23.3385],
          value: 0,
        },
        {
          deviceType: 0,
          name: "青海",
          center: [96.2402, 35.4199],
          value: 0
        },
        {
          deviceType: 0,
          name: "西藏",
          center: [88.7695, 31.6846],
          value: 0,
        },
        {
          deviceType: 0,
          name: "四川",
          center: [102.9199, 30.1904],
          value: 0,
        },
        {
          deviceType: 0,
          name: "宁夏",
          center: [105.9961, 37.3096],
          value: 0,
        },
        {
          deviceType: 0,
          name: "海南",
          center: [109.9512, 19.2041],
          value: 0,
        },
        {
          deviceType: 0,
          name: "台湾",
          center: [121.0254, 23.5986],
          value: 0,
        },
        {
          deviceType: 0,
          name: "香港",
          center: [115.2578, 22.0242],
          value: 0,
        },
        {
          deviceType: 0,
          name: "澳门",
          center: [112.5547, 21.5242],
          value: 0,
        },
      ]
}
const mutations={
    updateChinaData(state,chinaDate){
        state.chinaData=chinaDate
    }
}
const getters={
    getChinaData(state){
        return state.chinaData
    }
}
const actions={}
export default {
    namespaced:true,
    getters,
    state,
    mutations,
    actions
}