class LacalCache {
    // 本地缓存
    setCache(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    }
    // 本地读取数据
    getCache(key) {
        const value = window.localStorage.getItem(key)
        if (value) {
            return JSON.parse(value)
        }else{
            return false
        }
    }
    // 删除指定缓存的数据
    delCache(key) {
        window.localStorage.removeItem(key)
    }
    // 删除所有缓存
    clearCache() {
        window.localStorage.clear()
    }
}
let cache=new LacalCache()
export default cache