const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
    children: [
      {
        path: '/',
        redirect: {
          name: "home"
        }
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/index/home.vue"),
        meta: { title: "首页" }
      },
      {
        path: "/introduce",
        name: "introduce",
        component: () => import("@/views/about/introduce.vue"),
        meta: { title: "大赛介绍" }
      },
      {
        path: "/rule",
        name: "rule",
        component: () => import("@/views/about/rule.vue"),
        meta: { title: "大赛章程" }
      },
      {
        path: "/program",
        name: "program",
        component: () => import("@/views/about/program.vue"),
        meta: { title: "电视节目" }
      },
      {
        path: "/consultant",
        name: "consultant",
        component: () => import("@/views/about/consultant.vue"),
        meta: { title: "专家顾问" }
      }, {
        path: "/unit",
        name: "unit",
        component: () => import("@/views/about/unit.vue"),
        meta: { title: "大赛单元" }
      }, {
        path: "/regulation",
        name: "regulation",
        component: () => import("@/views/index/regulation.vue"),
        meta: { title: "竞赛规则" }
      },
      {
        path: "/dynamic",
        name: "dynamic",
        component: () => import("@/views/dynamic/dynamic.vue"),
        meta: { title: "竞赛动态" }

      }, {
        path: "/dynamicDetails/:id",
        name: "dynamicDetails",
        component: () => import("@/views/dynamic/dynamicDetails.vue"),
        meta: { title: "竞赛动态详情" }

      },
      {
        path: "/MediaAttitude",
        name: "MediaAttitude",
        component: () => import("@/views/socialResponse/MediaAttitude.vue"),
        meta: { title: "媒体热议" }
      },
      {
        path: "/AttitudeDetails/:id/:page",
        name: "AttitudeDetails",
        component: () => import("@/views/socialResponse/AttitudeDetails.vue"),
        meta: { title: "媒体热议详情" }
      },
      {
        path: "/carryOut",
        name: "carryOut",
        component: () => import("@/views/socialResponse/carryOut.vue"),
        meta: { title: "各地开展" }
      },
      {
        path: "/works",
        name: "works",
        component: () => import("@/views/works/works.vue"),
        meta: { title: "佳作展示" }
      },
      {
        path: "/worksDetails/:id",
        name: "worksDetails",
        component: () => import("@/views/works/worksDetails.vue"),
        meta: { title: "作品详情" }
      },
      {
        path: "/query",
        name: "query",
        component: () => import("@/views/credential/query.vue"),
        meta: { title: "成绩查询" }
      },
      {
        path: "/credentialDetails",
        name: "credentialDetails",
        component: () => import("@/views/credential/credentialDetails.vue"),
        meta: { title: "成绩查询详情" }
      },
	  
	  {
	    path: "/sixCredentialDetails",
	    name: "sixCredentialDetails",
	    component: () => import("@/views/credential/sixCredentialDetails.vue"),
	    meta: { title: "成绩查询详情" }
	  },
      {
        path: "/StudyFinland",
        name: "StudyFinland",
        component: () => import("@/views/StudyFinland/index.vue"),
        meta: { title: "报名中心" }
      },
      {
        path: "/approve",
        name: "approve",
        component: () => import("@/views/StudyFinland/approve.vue"),
        meta: { title: "机构认证" }
      },
      {
        path: "/ccieDetails/:id",
        name: "ccieDetails",
        component: () => import("@/views/StudyFinland/ccieDetails.vue"),
        meta: { title: "证书详情" }
      },
	  {
	    path: "/sixCcieDetails/:id",
	    name: "sixCcieDetails",
	    component: () => import("@/views/StudyFinland/sixCcieDetails.vue"),
	    meta: { title: "证书详情" }
	  },
      {
        path: "/apply/:code",
        name: "apply",
        component: () => import("@/views/StudyFinland/apply.vue"),
        meta: { title: "上传作品" }
      },
    ]
  },
  {
    path:"/login",
    name:"login",
    component: () => import("@/views/login.vue"),
    meta: { title: "登录" }
  },
  {
    path:"/antiFake",
    name:"antiFake",
    component:()=>import("@/views/antiFake/antiFake.vue"),
    meta:{title:"防伪查询"}
  },
  {
    path:"/antiFakeDetails/:code",
    name:"antiFakeDetails",
    component:()=>import("@/views/antiFake/antiFakeDetails.vue"),
    meta:{title:"防伪信息详情"}
  },
  {
	path: "/certificateQuery",
	name: "certificateQuery",
	component: () => import("@/views/credential/certificateQuery.vue"),
	meta: { title: "成绩证书查询" }
  },
  {
  	path: "/certificateQueryTwo",
  	name: "certificateQueryTwo",
  	component: () => import("@/views/credential/certificateQueryTwo.vue"),
  	meta: { title: "教师/学校证书查询" }
  },
  {
  	path: "/certificateUpload",
  	name: "upload",
  	component: () => import("@/views/credential/upload.vue"),
  	meta: { title: "证书上传" }
  },
]
export default routes