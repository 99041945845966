import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/route.js"       //路由
import cache from '@/utils/cache' //缓存封装

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})
let list = {
  login: '/login',
  while: [
    'index',
    'home',
    'regulation',
    'introduce',
    'rule',
    'unit',
    'program',
    'consultant',
    'dynamic',
    'dynamicDetails',
    'MediaAttitude',
    'AttitudeDetails',
    'carryOut',
    'works',
    'worksDetails',
    'query',
    'login',
    'ceshi',
    'antiFake',
    'antiFakeDetails',
	'credentialDetails',
	'ccieDetails',
	'certificateQuery',
	'certificateQueryTwo',
	'upload',
	'sixCredentialDetails',
	'sixCcieDetails'
	
  ]
}
router.beforeEach((to, from, next) => {
  let user_info = cache.getCache('user_info')
  setTimeout(() => {
    document.documentElement.scrollTop = 0;
  }, 50);
  if (user_info) {
    return next()
  } else {
    let islogin = list.while.indexOf(to.name)
    if (islogin != -1) {
      return next()
    } else {
      return next({
        path: list.login
      })
    }
  }
})
export default router;
