import {
    mapState,
    mapGetters,
    mapMutations,
    mapActions
} from "vuex";
const Stock = {
    computed: {
        ...mapGetters('stock', ['getWebConfig','getVaidPhone','getVaidIdCard','getTableTitle','getCredentialTitle'])
    },
    methods: {
        ...mapMutations('stock', ['updateWebConfig'])
    }
}
const chinaMap = {
    computed: {
        ...mapGetters('chinaMap', ['getChinaData'])
    },
    methods: {
        ...mapMutations('chinaMap', ['updateChinaData'])
    }
}

const para = {
    computed: {
        ...mapGetters('para', ['getPara'])
    },
    methods: {
        ...mapMutations('para', ['setPara'])
    }
}

export {
    Stock,
    chinaMap,
    para
}