<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { Stock } from "@/store/mixin.js";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  mixins: [Stock],
  created() {
    this.getConfig();
    // 禁止打开控制台
    // this.htmlDisable();
    // 下载地址列表
    // this.getAddressList()
  },
  methods: {
    getAddressList(){
      console.log('地址列表',regionData);
      let stringData =JSON.stringify(regionData)
      console.log(stringData);
      const blob = new Blob([stringData], {
          type: 'application/json'
      }),
      objectURL = URL.createObjectURL(blob),
      aTag = document.createElement('a')
      aTag.href = objectURL
      aTag.download = "address.json";
      aTag.click()
      URL.revokeObjectURL(objectURL)
    },
    // 禁用浏览器一些默认操作
    htmlDisable() {
      var h = window.innerHeight,
        w = window.innerWidth;
      ////禁用右键 （防止右键查看源代码）
      window.oncontextmenu = function () {
        return false;
      };
      ////在本网页的任何键盘敲击事件都是无效操作 （防止F12和shift+ctrl+i调起开发者工具）
      window.onkeydown = function (e) {
        e = window.event || e;
        let keycode = e.keyCode || e.which;
        if (
          e.altKey ||
          e.shiftKey ||
          (keycode >= 112 && keycode <= 123)
        ) {
          window.event.returnValue = false;
          return false;
        }
      };
      //如果用户在工具栏调起开发者工具，那么判断浏览器的可视高度和可视宽度是否有改变，如有改变则关闭本页面
      /* window.onresize = function () {
        if (h != window.innerHeight || w != window.innerWidth) {
          window.close();
          console.log(123);
          window.location = "about:blank";
        }
      }; */
    },
    async getConfig() {
      let webConfig = await this.$api.getConfig();
      console.log(webConfig);
      if (webConfig.code == 1) {
        this.updateWebConfig(webConfig.data);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/common.scss";
@import "./assets/iconfont/iconfont.css";
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
