import Vue from "vue";
import Vuex from "vuex";
import stock from "./module/stock"
import chinaMap from "./module/chinaMap";
import para from "./module/para";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    stock,
    chinaMap,
    para
  }
})
